import { CustomerRole } from '../services/constants';
import { Organization } from '../shared/models/customer';
import { Model } from '../shared/models/model';

export enum SettingType {
    Color,
    Image,
    Model,
    Organization,
    Select,
    Text,
    Toggle,
}

export interface Setting {
    id: string;
    title: string;
    description?: string;
    descriptionParams?: Record<string, string>;
    disabled?: boolean;
    type?: SettingType;
    default?: boolean | number | string;
    required?: boolean;
    config?:
        | ToggleConfig
        | SelectConfig
        | ImageConfig
        | TextConfig
        | OrganizationConfig
        | ModelConfig;
}

export interface ToggleConfig {
    onLabel: string;
    offLabel: string;
}

export interface SelectOption {
    name: string;
    value: string | number;
    icon?: string;
    groupId?: string;
}

export interface SelectGroup {
    id: string;
    name: string;
    icon?: string;
}

export interface SelectConfig {
    options: SelectOption[];
    isMultiple?: boolean;
}

export interface ImageConfig {
    faviconCustomerId?: number;
}

export interface TextConfig {
    errorMessages?: Record<string, () => string>;
    minimum?: number;
    maximum?: number;
    pattern?: RegExp;
    type?: string;
}

export interface OrganizationConfig {
    onlyRoles?: CustomerRole[];
    organizations?: Organization[];
}

export interface ModelConfig {
    models?: Model[];
}
