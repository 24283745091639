export const environment = {
    production: true,
    apiUrl: 'https://beta.api.spoke.zone',
    docsUrl: 'https://docs.spoke.zone',
    mqttUrl: 'beta.io.spoke.zone',
    mqttPort: 4083,
    mode: 'default',
    language: 'en',
    aot: true,
    outputHashing: 'all',
    isSpokeZone: true,
    isLenticul: false,
    mapId: 'ee981fec32fcdfac',
    baseLatitude: 38,
    baseLongitude: -97,
    platformName: 'Spoke.Zone',
    version: 'a8cbcec9-beta',
};
