import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, throttleTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { StatisticsResults } from 'src/app/shared/models/historical-data';
import { Granularity } from 'src/app/shared/models/report';
import { Chart } from '../models/chart';
import { generateUuid, SignalResourceType } from '../models/resource-type';
import { ChartSignal, StatisticSignal, StatisticType } from '../models/signal';
import { getFromStorage } from 'src/app/shared/storage-utils';
import { cloneArray } from 'src/app/shared/common';

export type ChartDataConfig = {
    startDate: Date;
    endDate: Date;
    granularity?: Granularity;
    dataFileId?: number;
};

export type ListenConfig = {
    // The chart that is listening for data updates.
    chart: Chart;
    // The destroy reference for the chart component.
    destroyRef: DestroyRef;
};

type CurrentData = {
    hasLoaded: boolean;
    statsSignalUuids: string[];
    results: StatisticsResults;
    config: ChartDataConfig | null;
};

export type SnapshotUpdate = {
    // The configuration that was used to load the data
    config: ChartDataConfig;
    // The signals to display the data with
    signals: ChartSignal[];
    // The current snapshot of the chart's series data
    seriesSnapshot: (Date | number | string)[][];
    // The current snapshot of the chart's aggregate data
    aggregateSnapshot: Record<string, number>;
};

type ChartLoadedStatus =
    | 'unloaded'
    | 'loading'
    | 'too-much-data'
    | 'error'
    | 'loaded'
    | 'empty';

const maxStatisticSignalsPerRequest = 30;

@Injectable()
export class ChartDataService {
    private api = inject(ApiService);

    private _config: ChartDataConfig;
    private _granularity$ = new BehaviorSubject<Granularity>(null);
    private chartStatuses: Record<string, ChartLoadedStatus> = {};
    private currentData$ = new BehaviorSubject<CurrentData>({
        hasLoaded: false,
        statsSignalUuids: [],
        results: emptyResults(),
        config: null,
    });

    get config() {
        return this._config;
    }

    get granularity$() {
        return this._granularity$;
    }

    set granularity(_granularity: Granularity) {
        if (!this.granularity$.value) {
            this._granularity$.next(_granularity);
        }
    }

    status(chart: Chart) {
        return this.chartStatuses[chart.id] ?? 'unloaded';
    }

    // TODO: figure out a better way to do this, so that we can remove
    //       ReportComponent.applyFilters
    updateConfig(config: ChartDataConfig) {
        this._config = config;
        // Clear all the chart statuses, because they all need re-loaded.
        this.chartStatuses = {};
        this.currentData$.next({
            hasLoaded: false,
            statsSignalUuids: [],
            results: emptyResults(),
            config,
        });
    }

    /* Refresh a single chart. */
    refreshOne(chart: Chart) {
        this.markAsUnloaded(chart);
        this.load([chart]);
    }

    markAsUnloaded(chart: Chart) {
        this.updateStatuses([chart], 'unloaded');
    }

    /* Load the data for any unloaded charts */
    async load(charts: Chart[]) {
        // Determine which charts need loaded
        charts = charts.filter((chart) => this.status(chart) == 'unloaded');
        const currentData = this.currentData$.value;
        const results = currentData.results;
        const statsSignalUuids = currentData.statsSignalUuids;
        const signals = getAllStatSignals(charts).filter(
            (s) =>
                // Include signals haven't been loaded yet.
                !statsSignalUuids.includes(s.uuid) ||
                // Re-request all signals when granularity is raw.
                // See spoke-zone-web#1829 for more context.
                this._config.granularity == 'raw',
        );
        const updateChartStatusesAfterLoad = () => {
            const emptyCharts = [];
            const loadedCharts = [];

            for (const chart of charts) {
                const isEmpty = this.isEmptyChart(chart, results);
                if (isEmpty) {
                    emptyCharts.push(chart);
                } else {
                    loadedCharts.push(chart);
                }
            }
            this.updateStatuses(emptyCharts, 'empty');
            this.updateStatuses(loadedCharts, 'loaded');
        };
        if (signals.length > 0) {
            this.updateStatuses(charts, 'loading');
            for (
                let i = 0;
                i < signals.length;
                i += maxStatisticSignalsPerRequest
            ) {
                const params = {
                    ...this.config,
                    signals: signals.slice(
                        i,
                        i + maxStatisticSignalsPerRequest,
                    ),
                };
                if (!params.endDate) {
                    delete params.endDate;
                }
                if (!params.granularity) {
                    delete params.granularity;
                }
                try {
                    const stats =
                        await this.api.historicalData.getStatistics(params);
                    // TODO: use more sophisticated error system
                    if (stats?.errors) {
                        this.updateStatuses(
                            charts,
                            stats?.errors
                                .map(({ type }) => type)
                                .includes('limit-exceeded')
                                ? 'too-much-data'
                                : 'error',
                        );
                    } else {
                        this.granularity = stats.granularity;
                        // Add new results
                        addNewResults(results, stats);
                        // Determine which signals actually received results.
                        const seriesUuids = Object.keys(
                            results.series[0] ?? {},
                        );
                        const aggregateUuids = Object.keys(results.aggregates);
                        const receivedUuids =
                            seriesUuids.concat(aggregateUuids);
                        // Track which signals are in the cache
                        statsSignalUuids.push(...receivedUuids);
                        updateChartStatusesAfterLoad();
                    }
                } catch ({ error }) {
                    // TODO: use more sophisticated error system
                    this.updateStatuses(
                        charts,
                        error?.errors?.includes('granularity is too small')
                            ? 'too-much-data'
                            : 'error',
                    );
                    break;
                }
            }
        } else {
            updateChartStatusesAfterLoad();
        }
        // We need to trigger this every time, even if no new data was loaded.
        // It triggers updates in the Chart widgets (through the `listen`
        // observable).
        this.currentData$.next({
            hasLoaded: true,
            config: this.config,
            statsSignalUuids,
            results,
        });
    }

    /**
     * Allows a chart's component to listen to the relevant data updates.
     */
    listen(listenConfig: ListenConfig): Observable<SnapshotUpdate> {
        return this.currentData$.pipe(
            takeUntilDestroyed(listenConfig.destroyRef),
            filter(({ hasLoaded }) => hasLoaded && listenConfig.chart !== null),
            map(({ results }) => {
                const signals = prepSignals(
                    listenConfig.chart.signals,
                    // TODO: use userIdentifier for clients
                    (deviceId) => this.api.devices.get(deviceId).identifier,
                );
                return {
                    config: this.config,
                    signals,
                    seriesSnapshot: getSeriesSnapshot(results, signals),
                    aggregateSnapshot: getAggregateSnapshot(results, signals),
                };
            }),
            // Only send updates once a second max, to avoid performance issues.
            throttleTime(1000),
        );
    }

    private updateStatuses(charts: Chart[], status: ChartLoadedStatus) {
        charts.forEach(({ id }) => (this.chartStatuses[id] = status));
    }

    /**
     * Check if the given chart has no non-time signal data for the given
     * results.
     */
    private isEmptyChart(chart: Chart, results: StatisticsResults) {
        const signals = prepSignals(chart.signals);
        const nonTimeSignals = signals.filter(
            ({ resource }) => resource.type !== SignalResourceType.Time,
        );
        if (nonTimeSignals.length == 0) {
            return true;
        }
        const seriesSnapshot = getSeriesSnapshot(results, nonTimeSignals);
        const aggregateSnapshot = getAggregateSnapshot(results, nonTimeSignals);
        const emptySeries = seriesSnapshot.every((item) =>
            item.every((value) => value == null),
        );
        const emptyAggregates = Object.values(aggregateSnapshot).every(
            (value) => value == null,
        );
        return emptySeries && emptyAggregates;
    }
}

/**
 * Prepare user-inputted signals for usage with the stats API and components.
 * - Filter out hidden signals.
 * - Split separated signals by device (signals that have combineData == false).
 * - If the getDeviceName function is provided, append the device name to the end
 *   of separated signal names.
 */
export const prepSignals = (
    signals: ChartSignal[],
    getDeviceName: ((deviceId: number) => string) | null = null,
): ChartSignal[] => {
    const output: ChartSignal[] = [];
    const shownSignals = cloneArray(signals.filter(({ hidden }) => !hidden));
    for (const signal of shownSignals) {
        switch (signal.resource.type) {
            case SignalResourceType.Log: {
                if (
                    signal.resource.threshold?.operator == null ||
                    signal.resource.threshold?.value == null
                ) {
                    // Automatically remove invalid threshold
                    delete signal.resource.threshold;
                }
                if (signal.resource.combineData) {
                    output.push(signal);
                } else {
                    // Separate this signal by device.
                    for (const deviceId of signal.resource.deviceIds) {
                        let name = signal.name;
                        if (getDeviceName) {
                            name += ' - ' + getDeviceName(deviceId);
                        }
                        output.push({
                            ...signal,
                            uuid: signal.uuid + '-' + deviceId,
                            name,
                            resource: {
                                ...signal.resource,
                                deviceIds: [deviceId],
                                combineData: true,
                            },
                        });
                    }
                }
                break;
            }
            case SignalResourceType.Time: {
                const isUtcTime = getFromStorage('isUtcTime') === 'true';
                if (!isUtcTime) {
                    signal.resource = {
                        ...signal.resource,
                        offset: new Date().getTimezoneOffset(),
                    };
                }
                output.push(signal);
                break;
            }
            default:
                output.push(signal);
                break;
        }
    }
    return output;
};

const getAllStatSignals = (charts: Chart[]): StatisticSignal[] => {
    const allSignals: StatisticSignal[] = [];
    const uuids = new Set<string>();
    charts.forEach((chart) => {
        const signals = chart.statisticSignals;
        signals.forEach((signal) => {
            if (!uuids.has(signal.uuid)) {
                allSignals.push(signal);
                uuids.add(signal.uuid);
            }
        });
    });
    return allSignals;
};

const emptyResults = (): StatisticsResults => ({
    series: [],
    aggregates: {},
    granularity: null,
});

const addNewResults = (existing: StatisticsResults, add: StatisticsResults) => {
    if (existing.series.length == 0) {
        existing.series = add.series;
    } else {
        existing.series.forEach((item, i) => {
            Object.assign(item, add.series[i]);
        });
    }

    for (const [uuid, value] of Object.entries(add.aggregates)) {
        existing.aggregates[uuid] = value;
    }
};

/**
 * Get the snapshot of the series data for the given chart signals.
 */
const getSeriesSnapshot = (
    results: StatisticsResults,
    chartSignals: ChartSignal[],
) =>
    results.series.map((item) =>
        chartSignals.map(
            (signal) => item[generateUuid(signal, StatisticType.Series)],
        ),
    );

/**
 * Get the snapshot of the aggregate data for the given chart signals.
 */
const getAggregateSnapshot = (
    results: StatisticsResults,
    chartSignals: ChartSignal[],
) =>
    Object.fromEntries(
        // Convert stats UUID to ChartSignal UUID
        chartSignals.map((signal) => [
            signal.uuid,
            results.aggregates[generateUuid(signal, StatisticType.Aggregate)],
        ]),
    );
