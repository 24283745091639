import {
    StatisticsParams,
    StatisticsResults,
} from 'src/app/shared/models/historical-data';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';

export class HistoricalDataApi extends BasicRouteApi {
    get path() {
        return 'historical-data';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async getStatistics(params: StatisticsParams): Promise<StatisticsResults> {
        try {
            const result = await this.retrieve<{ data: StatisticsResults }>(
                'statistics-mvp',
                {
                    ...params,
                    signals: JSON.stringify(params.signals),
                },
            );
            // TODO: error handling
            return result.data;
        } catch (err) {
            return err.error;
        }
    }
}
